import React from "react";
import {
  CloudArrowUpIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import Container from "../ui/Container";

const features = [
  {
    name: "AI",
    description:
      "Collaborative and decentralized AI computation is now available to anyone, anywhere.",
    icon: FingerPrintIcon,
  },
  {
    name: "Storage",
    description:
      "Affordable, faster and more secure decentralized storage for dApps and developers.",
    icon: ServerIcon,
  },
  {
    name: "IPFS",
    description: "Simple and reliable pinning infrastructure.",
    icon: Cog6ToothIcon,
  },
  {
    name: "Streaming",
    description:
      "Enabling businesses to deliver high-quality video content globally.",
    icon: CloudArrowUpIcon,
  },
];

const PoweredSection = () => {
  return (
    <section className="bg-black py-24 sm:py-32">
      <Container>
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-base font-semibold leading-7 text-secondary-100">
            You can run a Node and contribute.
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Powered By People
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Unlocking Web3 with DePIN technology. Experience AI, secure storage,
            and seamless streaming capabilities like never before
          </p>
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <feature.icon
                  className="absolute left-1 top-1 h-5 w-5 text-secondary-100"
                  aria-hidden="true"
                />
                {feature.name}
              </dt>{" "}
              <dd className="inline">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </Container>
    </section>
  );
};

export default PoweredSection;
