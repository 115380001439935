"use client";

import { Link } from "react-router-dom";
import Container from "../ui/Container";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";

const menuItems = [
  {
    text: "AI Compute",
    description: "Web3 AI Ecosystem",
    to: "/",
  },
  {
    text: "Store",
    description: "Fast, Reliable Web3 Storage",
    to: "/",
  },
  {
    text: "Stream",
    description: "Web3 Video Streaming",
    to: "/",
  },
  {
    text: "Node",
    description: "DZCEN",
    to: "/",
  },
];

const Header = () => {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const yOffset = -100; // Adjust the offset as needed
      const y =
        section.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <header className="sticky left-0 right-0 top-3 z-50 h-0">
      <Container>
        <div>
          <div className="rounded-lg border border-black bg-white">
            <div className="flex flex-row justify-start divide-x divide-black">
              <div className=" min-w-[9rem] flex-grow md:flex-shrink md:flex-grow-0 xl:flex-grow rounded-l-lg bg-white text-black">
                <Link
                  className="h-full flex items-center gap-2 px-4 py-3 md:p-4"
                  to="/"
                >
                  <img
                    className="h-7 object-contain"
                    src="/assets/images/favicon.jpg"
                    alt="logo"
                  />
                  <i className="text-2xl font-extrabold uppercase">Dzcen</i>
                </Link>
              </div>
              <div className="hidden flex-grow md:block">
                <nav className="relative z-10 flex-1 items-center justify-center max-w-none block h-full">
                  <div className="relative">
                    <ul className="group flex flex-1 list-none items-center justify-center divide-x divide-black space-x-0 w-full">
                      {menuItems.map((item, index) => (
                        <li key={index} className="flex-1 hidden md:block ">
                          <Link
                            to={item.to}
                            disabled={true}
                            className=" flex min-h-[4.5rem] relative flex-col items-center justify-center px-2 xl:px-6 py-4 xl:min-w-[13rem] xl:items-start transition-[color,background-color] bg-white duration-300 cursor-auto"
                          >
                            <span className="font-formula text-base font-extrabold leading-5">
                              <span className="line-clamp-1">{item.text}</span>
                            </span>
                            <span className="hidden xl:block text-sm">
                              <span className="line-clamp-1">
                                {item.description}
                              </span>
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="absolute left-0 top-full flex justify-center w-full" />
                </nav>
              </div>
              <div className="flex-shrink">
                <Popover className="relative">
                  <Popover.Button className="block cursor-pointer p-5 md:p-6 outline-none cursor-auto">
                    <Bars3Icon className="h-6 w-6" />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute -right-20 md:right-0 z-10 mt-5 px-4">
                      <div className="min-w-72 md:min-w-max max-w-sm flex-auto rounded-lg bg-white p-2 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 overflow-auto max-h-96 md:max-h-full">
                        <ul>
                          {menuItems.map((item, index) => (
                            <li
                              key={index}
                              disabled={true}
                              className="py-1 md:hidden border-b border-black"
                            >
                              <Link
                                to={item.to}
                                className="relative group flex flex-col px-6 py-4 rounded-md text-black transition duration-300  md:p-6 outline-none !p-4 cursor-auto"
                              >
                                <span className="font-formula text-base leading-[1.375em] font-extrabold tracking-[0.01em]">
                                  {item.text}
                                </span>
                                <span className="font-neue text-sm leading-[130%]">
                                  {item.description}
                                </span>
                              </Link>
                            </li>
                          ))}

                          <li className="py-1 border-b border-black">
                            <button
                              className="relative group flex flex-col px-6 py-4 rounded-md text-black transition duration-300 hover:bg-black hover:text-white md:p-6 outline-none !p-4 cursor-auto"
                              // href="#eco-section"
                              onClick={() => scrollToSection("eco-section")}
                            >
                              <span className="font-formula text-base leading-[1.375em] font-extrabold tracking-[0.01em]">
                                Ecosystem
                              </span>
                              <span className="font-neue text-sm leading-[130%]">
                                Discover the DZCEN Network Ecosystem
                              </span>
                            </button>
                          </li>
                          {/* <li className="py-1 border-b border-black">
                            <Link
                              className="relative group flex flex-col px-6 py-4 rounded-md text-black transition duration-300 hover:bg-black hover:text-white md:p-6 outline-none !p-4"
                              to
                            >
                              <span className="font-formula text-base leading-[1.375em] font-extrabold tracking-[0.01em]">
                                Docs
                              </span>
                              <span className="font-neue text-sm leading-[130%]">
                                Explore DZCEN Documentation
                              </span>
                            </Link>
                          </li> */}
                          <li className="py-1 border-b border-black">
                            <button
                              className="relative group flex flex-col px-6 py-4 rounded-md text-black transition duration-300 hover:bg-black hover:text-white md:p-6 outline-none !p-4 cursor-auto"
                              // href="#howitworks-section"
                              onClick={() =>
                                scrollToSection("howitworks-section")
                              }
                            >
                              <span className="font-formula text-base leading-[1.375em] font-extrabold tracking-[0.01em]">
                                BlockChain
                              </span>
                              <span className="font-neue text-sm leading-[130%]">
                                DZCEN Blockchain Explorer
                              </span>
                            </button>
                          </li>
                          <li className="py-1 border-b border-black">
                            <a
                              className="relative group flex flex-col px-6 py-4 rounded-md text-black transition duration-300 hover:bg-black hover:text-white md:p-6 outline-none !p-4 cursor-auto"
                              href="#partner-section"
                            >
                              <span className="font-formula text-base leading-[1.375em] font-extrabold tracking-[0.01em]">
                                dApps
                              </span>
                              <span className="font-neue text-sm leading-[130%]">
                                The DZCEN dApps Exosystem
                              </span>
                            </a>
                          </li>
                        </ul>
                        {/* <div className="space-y-3 p-3">
                          <Link
                            className="relative group flex flex-col px-6 py-4 text-black transition duration-300 md:p-6 outline-none !p-0 lg:hover:bg-transparent lg:hover:text-green"
                            to="/develop"
                          >
                            <span className="font-formula text-base leading-[1.375em] font-extrabold tracking-[0.01em]">
                              Develop
                            </span>
                          </Link>
                          <Link
                            className="relative group flex flex-col px-6 py-4 text-black transition duration-300 md:p-6 outline-none !p-0 lg:hover:bg-transparent lg:hover:text-green"
                            to="/blog"
                          >
                            <span className="font-formula text-base leading-[1.375em] font-extrabold tracking-[0.01em]">
                              Blog
                            </span>
                          </Link>
                          <Link
                            className="relative group flex flex-col px-6 py-4 text-black transition duration-300 md:p-6 outline-none !p-0 lg:hover:bg-transparent lg:hover:text-green"
                            to="/press-kit"
                          >
                            <span className="font-formula text-base leading-[1.375em] font-extrabold tracking-[0.01em]">
                              Press Kit
                            </span>
                          </Link>
                        </div> */}
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </div>
              <div className="flex-shrink">
                <button
                  type="button"
                  className="cursor-auto rounded-r-lg h-full flex items-center gap-2 px-4 py-3 md:p-4 transition-[color,background-color] bg-white duration-300 hover:bg-black hover:text-white"
                >
                  <span className="font-bold">Coming Soon</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
