import React from "react";
import Container from "../ui/Container";

const ImageSection = () => {
  return (
    <section id="eco-section" className="bg-white py-16">
      <Container>
        <h2 className="mb-20 text-4xl font-bold font-heading text-center tracking-px-n leading-tight max-w-xl mx-auto">
          AI Compute and Storage Resources Architecture
        </h2>
        <div className="aspect-[16/6]">
          <img
            className="h-full w-full object-contain"
            src="/assets/images/data-image-1.jpg"
            alt="..."
          />
        </div>
      </Container>
    </section>
  );
};

export default ImageSection;
