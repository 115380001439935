import React from "react";
import Container from "../ui/Container";

const HowWorksSection = () => {
  return (
    <section id="howitworks-section" className="py-24">
      <Container>
        <h1 className="text-center text-3xl lg:text-5xl font-bold font-heading mb-4">
          How it works
        </h1>
        <p className="text-center text-gray-600 mb-20">
          What steps do I need to take to join?
        </p>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-1/3 p-4">
            <div className="border rounded-3xl px-4 lg:px-10 py-6 lg:py-12 relative overflow-hidden h-full max-w-md mx-auto border-gray-500">
              <h2 className="text-3xl font-bold font-heading mb-6">
                Blockchain
              </h2>
              <p className="text-gray-600 ">
                DZCEN blockchain empowers web3 builders with a one-click
                integration of dApps and support for popular smart contract
                languages across Ethereum and Cosmos networks.
              </p>
              <p className="font-bold pb-40 pt-10">DZCEN blockchain Explorer</p>
              <div className="absolute -bottom-6 right-4">
                <div className="flex justify-end gap-4 mb-4">
                  <div className="w-16 h-16 rounded-xl bg-gray-50" />
                  <div className="w-16 h-16 rounded-xl bg-gray-50 bg-opacity-50" />
                  <div className="w-16 h-16 rounded-xl bg-gray-50 bg-opacity-50" />
                </div>
                <div className="flex gap-4">
                  <div className="w-16 h-16 rounded-xl bg-gray-50 bg-opacity-50" />
                  <div className="w-16 h-16 rounded-xl bg-gray-50 bg-opacity-50" />
                  <div className="w-16 h-16 rounded-xl bg-gray-50 bg-opacity-50" />
                  <div className="w-16 h-16 rounded-xl bg-gray-50 bg-opacity-50" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/3 p-4">
            <div className="border rounded-3xl px-4 lg:px-10 py-6 lg:py-12 relative overflow-hidden h-full max-w-md mx-auto border-gray-500">
              <h2 className="text-3xl font-bold font-heading mb-6">Nodes</h2>
              <p className="text-gray-600">
                A global community of DZCEN nodes supports the DZCEN dCDN, with
                thousands of individuals utilizing spare computing resources to
                power web3 AI, storage and media delivery.
              </p>
              <p className="font-bold pb-40 pt-10">Run an DZCEN Node</p>
              <div className="absolute -bottom-6 right-4">
                <div className="flex justify-center gap-4 mb-4">
                  <div className="w-16 h-16 rounded-xl bg-gray-50" />
                  <div className="w-16 h-16 rounded-xl bg-gray-50" />
                </div>
                <div className="flex gap-4">
                  <div className="w-16 h-16 rounded-xl bg-gray-50 bg-opacity-50" />
                  <div className="w-16 h-16 rounded-xl bg-gray-50 bg-opacity-50" />
                  <div className="w-16 h-16 rounded-xl bg-gray-50 bg-opacity-50" />
                  <div className="w-16 h-16 rounded-xl bg-gray-50 bg-opacity-50" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/3 p-4">
            <div className="border rounded-3xl px-4 lg:px-10 py-6 lg:py-12 relative overflow-hidden h-full max-w-md mx-auto border-gray-500">
              <h2 className="text-3xl font-bold font-heading mb-6">Infra</h2>
              <p className="text-gray-600">
                DZCEN Decentralized Content Delivery Network (dCDN) powers AI
                computation, data storage, and seamless media and video
                streaming for dApps within the DZCEN Network.
              </p>
              <p className="font-bold pb-40 pt-10">Build on DZCEN</p>
              <div className="absolute -bottom-6 right-4">
                <div className="flex justify-end gap-4 mb-4">
                  <div className="w-16 h-16 rounded-xl bg-gray-50" />
                  <div className="w-16 h-16 rounded-xl bg-gray-50 bg-opacity-50" />
                  <div className="w-16 h-16 rounded-xl bg-gray-50 bg-opacity-50" />
                </div>
                <div className="flex gap-4">
                  <div className="w-16 h-16 rounded-xl bg-gray-50 bg-opacity-50" />
                  <div className="w-16 h-16 rounded-xl bg-gray-50 bg-opacity-50" />
                  <div className="w-16 h-16 rounded-xl bg-gray-50 bg-opacity-50" />
                  <div className="w-16 h-16 rounded-xl bg-gray-50 bg-opacity-50" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HowWorksSection;
