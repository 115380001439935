import React from "react";
import Container from "../ui/Container";

const HeroSection = () => {
  return (
    <section className="relative overflow-hidden bg-[#F4F4F4]">
      <video
        playsInline
        className="hero-video hidden sm:block invert pointer-events-none absolute left-0 top-0 -translate-y-[8%] sm:left-1/2 sm:top-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2 z-0 w-[calc(100%+2px)] md:h-[calc(100%+2px)] object-cover"
        autoPlay
        loop
        muted
      >
        <source src="/assets/videos/bg-h.mp4" type="video/mp4" />
      </video>
      <Container>
        <div className="relative z-10 flex items-end md:items-center md:aspect-video pb-[4.5rem] pt-[8.5rem] sm:pt-0 md:pb-0 sm:min-h-[36rem] md:min-h-[60rem] xl:min-h-screen md:pt-20 max-w-full">
          <div className="flex flex-col gap-8 md:gap-12">
            <img
              className="h-44 object-left object-contain"
              src="/assets/images/logonew.png"
              alt=".."
            />
            <div className="space-y-4 md:space-y-6">
              <h1 className="font-formula font-medium text-[2.5rem] leading-[120%] lg:text-[2.75rem] xl:text-[3rem] xl:tracking-[1px] max-w-none sm:max-w-[70%] lg:max-w-none">
                <span>Unlocking Web3 with</span>{" "}
                <br className="hidden lg:block" />
                <span className="text-green-dark">DePIN technology.</span>
              </h1>
              <p className="font-neue text-lg leading-[130%] tracking-[0.18px] lg:text-xl lg:tracking-[0.2px] xl:text-[1.375rem] max-w-sm">
                Experience AI, secure storage, and seamless streaming
                capabilities like never before
              </p>
            </div>
            {/* <div className="flex items-center flex-wrap gap-6">
                <button
                  type="button"
                  className="group flex flex-row items-center gap-4 rounded-full border transition duration-300 justify-center md:justify-start px-10 py-[0.875rem] md:py-[1.1875rem] bg-primary-500 text-white border-primary-500 hover:bg-primary-600 hover:text-white w-full sm:w-auto"
                >
                  <span className="block pt-1 font-formula font-bold text-[1rem] leading-6 whitespace-nowrap transition duration-300 md:text-[1.125rem]">
                    Our Vision
                  </span>
                </button>
                <button
                  type="button"
                  className="group flex flex-row items-center gap-4 rounded-full border transition duration-300 justify-center md:justify-start px-10 py-[0.875rem] md:py-[1.1875rem] border-black hover:bg-black hover:text-white w-full sm:w-auto"
                >
                  <span className="block pt-1 font-formula font-bold text-[1rem] leading-6 whitespace-nowrap transition duration-300 md:text-[1.125rem] text-black group-hover:text-white">
                    Node Rewards
                  </span>
                </button>
              </div> */}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HeroSection;
