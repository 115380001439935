import React from "react";
import HeroSection from "../components/sections/HeroSection";
import PoweredSection from "../components/sections/PoweredSection";
import HowWorksSection from "../components/sections/HowWorksSection";
import PlogosSection from "../components/sections/PlogosSection";
import NewsletterSection from "../components/sections/NewsletterSection";
import ImageSection from "../components/sections/ImageSection";

const HomePage = () => {
  return (
    <main>
      <HeroSection />
      <PoweredSection />
      <HowWorksSection />
      <ImageSection />
      {/* <RoadmapSection /> */}
      <PlogosSection />
      <NewsletterSection />
    </main>
  );
};

export default HomePage;
