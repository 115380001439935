import React from "react";
import Container from "../ui/Container";

const PlogosSection = () => {
  return (
    <section id="partner-section" className="py-16 md:py-28 overflow-hidden">
      <Container>
        <h2 className="mb-20 text-4xl font-bold font-heading text-center tracking-px-n leading-tight">
          Collaborations & Partnerships
        </h2>
        {/* <p className="mb-20 text-lg text-gray-600 text-center font-medium leading-normal md:max-w-xl mx-auto">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          varius enim in eros elementum tristique. Duis cursus mi quis.
        </p> */}
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-wrap items-center lg:-m-14">
            <div className="w-full sm:w-1/2 lg:w-1/3 p-6 lg:p-14">
              <img
                className="mx-auto h-full w-full  object-contain"
                src="/assets/images/p-logos/p-logo-2.png"
                alt="..."
              />
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-6 lg:p-14">
              <img
                className="mx-auto h-full w-full  object-contain"
                src="/assets/images/p-logos/p-logo-1.png"
                alt="..."
              />
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-6 lg:p-14">
              <img
                className="mx-auto h-full w-full aspect-[16/2] object-contain"
                src="/assets/images/p-logos/p-logo-3.png"
                alt="..."
              />
            </div>

            <div className="w-full sm:w-1/2 lg:w-1/3 p-6 lg:p-11">
              <img
                className="mx-auto h-full w-full aspect-[16/2] object-contain"
                src="/assets/images/p-logos/p-logo-4.png"
                alt="..."
              />
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-6 lg:p-11">
              <img
                className="mx-auto h-full w-full aspect-[16/2] object-contain"
                src="/assets/images/p-logos/p-logo-5.png"
                alt="..."
              />
            </div>
            <div className="w-full sm:w-1/2 lg:w-1/3 p-6 lg:p-11">
              <img
                className="mx-auto h-full w-full aspect-[16/2] object-contain"
                src="/assets/images/p-logos/p-logo-6.png"
                alt="..."
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default PlogosSection;
