import React from "react";
import Container from "../ui/Container";

const NewsletterSection = () => {
  return (
    <section className="pt-24 pb-4 overflow-hidden bg-white border-t border-black">
      <Container>
        <div className="py-14 bg-black rounded-2xl">
          <div className="md:max-w-3xl mx-auto text-center px-4">
            <span className="inline-block mb-4 text-sm text-secondary-100 font-medium tracking-tighter">
              Newsletter
            </span>
            <h2 className="mb-8 text-4xl text-white tracking-5xl">
              Receive occasional updates about the DZCEN network and our latest
              innovations
            </h2>
          </div>
          <form className="px-4">
            <div className="flex flex-wrap md:max-w-xl mx-auto -m-2">
              <div className="w-full md:flex-1 p-2">
                <div className="md:max-w-xl mx-auto border border-gray-900 focus-within:border-white overflow-hidden rounded-3xl">
                  <input
                    className="px-6 py-4 text-gray-300 w-full placeholder-gray-300 outline-none bg-transparent"
                    type="email"
                    placeholder="E-mail"
                    required
                  />
                </div>
              </div>
              <div className="w-full md:w-auto p-2">
                <button
                  type="submit"
                  className="inline-block px-14 py-4 font-medium tracking-2xl border-2 text-white border-secondary-500 bg-secondary-500 hover:bg-secondary-400 focus:ring-4 focus:ring-secondary-400 focus:ring-opacity-40 rounded-full transition duration-300"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </section>
  );
};

export default NewsletterSection;
